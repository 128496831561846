<template>
  <div class="set-point">
    <!-- 测点信息 -->
    <div class="div">
         <p class="emColor title">测点信息</p>
        <ul class="box center flexW msgList">
          <li class="box center" v-for="item in msgList.slice(0,5)" :key="item.id">
            <div class="box center labelTxt">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg>
              <span>{{item.title}}</span>
            </div>
            <div class="contentTxt ellipsis">{{item.value}}</div>
          </li>
        </ul>
        <ul class="box center flexW msgList">
          <li class="box center" v-for="item in msgList.slice(5,10)" :key="item.id">
            <div class="box center labelTxt">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg>
              <span>{{item.title}}</span>
            </div>
            <div class="contentTxt">{{item.value}}</div>
          </li>
        </ul>
         <ul class="box center flexW msgList">
          <li class="box center" v-for="item in msgList.slice(10,11)" :key="item.id">
            <div class="box center labelTxt">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg>
              <span>{{item.title}}</span>
            </div>
            <div class="contentTxt">{{item.value}}</div>
          </li>
        </ul>

    </div>

    
    <div class="multi-form">
      <p>数据分析设置</p>
      <!-- 时域统计值 -->
      <section>
        <span class="title">时域统计</span>
        <el-form :model="formData1" :inline="true" :rules="rules1" ref="formData1" v-if="quiet ==1">

          <el-form-item label="时间间隔" prop="num2" class="itemForm"  >
            <el-input v-model="formData1.num2" placeholder="请输入" @change="changeInput" class="textL" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
          
          <el-form-item label="时段长度" class="itemForm" prop="num3" >
            <el-input v-model="formData1.num3" placeholder="请输入" class="textL"  clearable/>
            <span class="unit">分钟</span>
          </el-form-item>
        </el-form>

        <el-form :model="formData7" :inline="true"  ref="formData7" v-show="quiet !=1">
          <el-form-item label="时间间隔" prop="" class="itemForm" >
            <el-input v-model="formData7.num2" placeholder="" class="textL" :disabled="true" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>

      </section>

      <section v-if="zhunjingtai == 1">
        <span class="title">准静态时域统计</span>
         <el-form :model="formData5" :inline="true" :rules="rules5" ref="formData5" v-if="quiet== 1">
          <el-form-item label="时间间隔" prop="" class="itemForm" v-if="formData5.number != 99" >
            <el-input v-model="formData5.num2" placeholder="请输入" class="textL" :disabled="true" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
        <el-form :model="formData5" :inline="true" :rules="rules5" ref="formData5" v-else>
          <!-- <el-form-item label="时段长度">
            <el-select v-model="formData1.number" placeholder="请选择" @change="changeNumber1">
              <el-option
                v-for="item in targetOptions1"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item v-if="formData5.number == 0" prop="num1" class="itemForm" label="小时" style="padding-left:20px;margin-left:0px">
              <el-input v-model="formData5.num1" placeholder="请输入" clearable/>
          </el-form-item> -->
          <el-form-item label="时间间隔" prop="" class="itemForm" v-if="formData5.number != 99" >
            <el-input v-model="formData5.num2" placeholder="" class="textL" :disabled="true" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
      </section>

               <!-- 频域分析 -->
      <section>
        <span class="title">频域分析</span>
        <el-form :model="formData3" :inline="true" :rules="rules3" ref="formData3" v-if="quiet==1">
          <el-form-item label="时段长度">
            <el-select v-model="formData3.number" placeholder="请选择" @change="changeNumber3" :disabled="true">
              <el-option
                v-for="item in targetOptions3"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item> 
        </el-form>
        <el-form :model="formData3" :inline="true" :rules="rules3" ref="formData3" v-else>
          <el-form-item label="时段长度">
            <el-select v-model="formData3.number" placeholder="请选择" @change="changeNumber3" >
              <el-option
                v-for="item in targetOptions3"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item> 
           <el-form-item label="" v-if="formData3.number == 0" prop="num1" class="itemForm" style="padding-left:20px;margin-left:0px">
              <el-input v-model="formData3.num1" placeholder="请输入" clearable/>
              <span class="unit">小时</span>
          </el-form-item> 
          <el-form-item label="时间间隔" prop="" class="itemForm" v-if="formData3.number != 99">
            <el-input v-model="formData3.num2" placeholder="" :disabled="true" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
          <el-form-item label="窗口长度" prop="num3" class="itemForm" v-if="formData3.number != 99">
             <el-input v-model="formData3.num3" placeholder="请输入"  clearable/>
              <!-- <span class="unit"></span> -->
          </el-form-item>
          <el-form-item label="平滑点数" prop="num4" class="itemForm" v-if="formData3.number != 99">
            <el-input v-model="formData3.num4" placeholder="请输入" clearable/>
             <!-- <span class="unit"></span> -->
          </el-form-item>
        </el-form>
      </section>


      <!-- 温度效应提取 -->
      <section>
        <span class="title">温度效应提取</span>
    
        <el-form :model="formData2" :inline="true" :rules="rules2" ref="formData2" >
          <el-form-item label="时段长度">
            <el-select v-model="formData2.number" placeholder="请选择" @change="changeNumber2">
              <el-option
                v-for="item in targetOptions2"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item> 
          <el-form-item label="分段时长" prop="num2" class="itemForm" v-if="formData2.number != 99 ">
            <el-input v-model="formData2.num2" placeholder="请输入" clearable/>
            <span class="unit">分钟</span>
          </el-form-item>
          <el-form-item label="延迟时间" prop="" class="itemForm"  v-if="formData2.number != 99 ">  
            <el-input v-model="formData2.num3" placeholder="" :disabled="true" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
           <el-form-item label="时间间隔" prop="num4" class="itemForm"  v-if="formData2.number != 99 && quiet != 1 ">
            <el-input v-model="formData2.num4" placeholder="请输入"  clearable/>
            <span class="unit">小时</span>
          </el-form-item>
           <el-form-item label="存储间隔" prop="num5" class="itemForm"  v-if="formData2.number != 99 && quiet != 1 ">
            <el-input v-model="formData2.num5" placeholder="请输入"  clearable/>
            <span class="unit">分钟</span>
          </el-form-item>
          

        </el-form>
      </section>


        
        <!-- 模态分析测点选择 -->
      <section>
        <span class="title">模态分析测点选择</span>
          <el-form :model="formData4" :inline="true" v-if="quiet==1" >
            <el-form-item label="是否启动">
                <el-select v-model="formData4.status" placeholder="请选择"  :disabled="true">
                <el-option label="是" value="1" />
                <el-option label="否" value="0"/>
              </el-select>
            </el-form-item> 
        </el-form>

  <!-- :rules="rules4" -->
        <el-form :model="formData4" :inline="true" v-else  ref="formData4">
          <el-form-item label="是否启动">
              <el-select v-model="formData4.status" placeholder="请选择" @change="changeNumber4"  >
                <el-option label="是" value="1" />
                <el-option label="否" value="0"/>
            </el-select>
          </el-form-item> 

        </el-form>
      </section>

                <!-- 趋势分析 -->
      <section>
        <span class="title">趋势分析</span>
        <el-form :model="formData6" :inline="true" :rules="rules6" ref="formData6">
          <el-form-item label="分析方法">
            <el-select v-model="formData6.number" placeholder="请选择" @change="changeNumber6" >
                <el-option label="多项式" value="1" />
                <el-option label="指数型" value="2"/>
                 <el-option label="不分析" value="0" />
             </el-select>
          </el-form-item> 
           <el-form-item label="阶数" v-if="formData6.number == 1" prop="num" class="itemForm" style="padding-left:20px;margin-left:0px">
              <el-input v-model="formData6.num" placeholder="请输入" clearable/>
              <span class="unit pointer" @click="getNum">-</span>
              <span class="unit pointer" @click="getNum6">+</span>
          </el-form-item> 
          <el-form-item label="分析时段" prop="num1" class="itemForm" v-if="this.formData6.number != 0">
            <el-input v-model="formData6.num1" placeholder="请输入"  clearable/>
            <span class="unit">小时</span>
          </el-form-item>
           <el-form-item label="时间间隔" prop="num2" class="itemForm" v-if="this.formData6.number != 0">
            <el-input v-model="formData6.num2" placeholder="请输入"  clearable/>
            <span class="unit">小时</span>
          </el-form-item>
           <el-form-item label="间隔点数" prop="num3" class="itemForm" v-if="this.formData6.number != 0">
            <el-input v-model="formData6.num3" placeholder="请输入"  clearable/>
            <!-- <span class="unit">小时</span> -->
          </el-form-item>
           <el-form-item label="存储间隔" prop="num4" class="itemForm" v-if="this.formData6.number != 0">
            <el-input v-model="formData6.num4" placeholder="请输入"  clearable/>
            <span class="unit">小时</span>
          </el-form-item>


        </el-form>
      </section>



    </div>
  
    <div class="btns">
      <el-button v-if="state ==1" @click="saveData" >保存</el-button>
      <el-button v-if="state ==2" class="hui">保存</el-button>
      <el-button @click="back">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {

  
  data() {
    var text1=(rule, value,callback)=>{    //不能为0，小数保留1位，正数
        let reg = /^[+]?(([0-9]\d*))(\.\d{1})?$/;      
        let flag = reg.test(value);

        if(!flag){
            callback(new Error('输入正数且最多一位小数'))
        }
        else if(value == 0){
            callback(new Error('输入正数且最多一位小数'))
        }
        else {
              callback()
        }
    };
    
    var checkedSerialNumber = (rule, value, callback) => {
     let reg = /^[0-9]+([.]{1}[0-9]+){0,1}$/;
       let flag = reg.test(value);

        if(!flag){
            callback(new Error('请输入正数'))
        }
        else if (!value){
              callback(new Error('请输入！'))
        }     
        else {
              callback()
        }
    };

    

    var checkedSerialNumber1 = (rule, value, callback) => {
     if (value) {
         if (!Number(value)) callback("请输入数字");
      } else {
        callback();
      }
    };
      //密码手机号
            var minData=(rule, value,callback)=>{
              let reg = /^[1-9]([0-9])*$/;
              let flag = reg.test(value);
                if (Number(this.formData6.num) > 4 ){
                      this.formData6.num = 4
                      // callback(new Error('最大阶数为4'))
                }  
                else if (Number(this.formData6.num) < 1 ){
                      this.formData6.num = 1
                      // callback(new Error('最小阶数为1'))
                }
                else if(!flag){
                   callback(new Error('需为正整数'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }
  
                else if (Number(this.formData6.num) > 4 || Number(this.formData6.num) < 1){
                      callback(new Error('请输入1-4'))
                } 
  
                else {
 
                      callback()
                }
            };
            var maxData=(rule, value,callback)=>{
               let reg = /^[1-9]([0-9])*$/;
               let flag = reg.test(value);

                if(!flag){
                   callback(new Error('必须为正整数'))
                }
                if (!value){
                      callback(new Error('请输入！'))
                }
                 else if (Number(this.formData4.num3) > 10 || Number(this.formData4.num3) < 1){
                      callback(new Error('请输入1-10'))
                }  
                // else if (Number(this.formData4.num2) > Number(this.formData4.num3)){
                //       callback(new Error('不能大于！'))
                // }     
                else {
 
                      callback()
                }
            };
            //正整数
            var zheng=(rule, value,callback)=>{
              let reg = /^[1-9]([0-9])*$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('需为正整数'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }     
                else {
                      callback()
                }
            };
            var zheng1=(rule, value,callback)=>{
              let reg = /^[0-9]([0-9])*$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('需为正整数'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }     
                else {
                      callback()
                }
            };
              //2的n次方
             var zheng2=(rule, value,callback)=>{
              let reg = /^[1-9]([1-9])*$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('需为正整数'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }     
                else {
                      callback()
                }
            };




            // 大于0包含小数
            var numberMax=(rule, value,callback)=>{
              let reg = /^([1-9]\d*(\.\d*)?)|(0\.\d*[1-9][0-9])|(0\.\d*[1-9])$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('请输入大于0的数值'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }   
                else {
 
                      callback()
                }
            };




    return {
        zhunjingtai:1, // 准静态是否先是
        startTime:'',
        detime:'',
        quiet: '0',
        readonly: true, //只读
        state:'',
        msgList:[
        {id:1,title:'测点编号',icon:'#iconbianhao',value:'SL070214'},
        {id:2,title:'监测类型',icon:'#iconshebeiguanli',value:'环境类'},
        {id:3,title:'监测指标',icon:'#iconzhibiao',value:'温度'},
        {id:4,title:'数据类型',icon:'#iconshuju1',value:'静态'},
        {id:5,title:'位置描述',icon:'#iconmiaoshu',value:'桥梁中部，第一根桥梁柱'},
        {id:6,title:'传感器型号',icon:'#iconqunfengshebeixinghao',value:'Rn100'},
        {id:7,title:'系数',icon:'#iconjuleixishu',value:'0.95'},
        {id:8,title:'单位',icon:'#iconwenduji',value:'℃'},
        {id:9,title:'精度',icon:'#iconslamjingdu-gongzuojuli',value:'0.5'},
        {id:10,title:'通讯地址',icon:'#icondianhua',value:'5216482'},
        {id:11,title:'采样时间间隔(秒)',icon:'#iconshijianjiange',value:''},
      ],
      form1: {},
      form2: {},
      //时域统计
      formData1: {
        num2:'',
         num3:'',
      },

      //准静态时域统计
       formData5: {
        status:'',
        number:'1小时',
        num1:'',
        num2:'',
      },



      // 时域分析处理时段长度
      // （0: 其他，1: 1分钟，2: 5分钟，3: 10分钟，4: 15分钟，5: 20分钟，6: 1小时，7: 1天，8: 1月）
       targetOptions1: [   
        {id:0,name: '其他'},   
        {id:1,name: '1分钟'},
        {id:2,name: '5分钟'},
        {id:3,name: '10分钟'},
        {id:4,name: '15分钟'},
        {id:5,name: '20分钟'},
        {id:6,name: '1小时'},
        {id:7,name: '1天'},
        {id:8,name: '1月'},
        {id:99,name: '不分析'},
      ],
       rules1: {
        // num1: [
        //   { required: true, message: '请输入', trigger: 'blur' },

        // ],
        num2: [
          // { required: true, message: '请输入', trigger: 'blur' },
          { validator: zheng, trigger: "blur" }    //
        ],
        num3: [
          // { required: true, message: '请输入', trigger: 'blur' },
          { validator: zheng, trigger: "blur" }    //
        ],
      }, 

      //温度效应提取
      formData2: {
        status:'',
        number:'3',         //时段长度
        num1:'',  
        num2:'',   //分析时长
        num3:'',   // 时间间隔
        num4:'',
        num5:'',
      },
      // 温度效应提取时段长度（0: 其他，1: 1分钟，2: 5分钟，3: 10分钟，4: 15分钟，5: 20分钟，6: 1小时，7: 1天，8: 1月）
      targetOptions2: [   
        {id:1, name: '3小时'},   
        {id:2, name: '6小时'},
        {id:3, name: '12小时'},
        {id:99,name: '不分析'},
      ],
       rules2: {
        num1: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        num2: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: zheng, trigger: "blur" }
        ],
        num3: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        num4: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: text1, trigger: "blur" }
        ],
         num5: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: zheng, trigger: "blur" }
        ],
      }, 

      formData3:{
        status:'',
        number: 7,
        num1:'',
        num2:'',
        num3:'',
        num4:'',
      },
      // 频域统计分析频率（0: 其他，1: 1分钟，2: 5分钟，3: 10分钟，4: 15分钟，5: 20分钟，6: 0.5小时，7: 1小时，8: 1.5小时）
      targetOptions3: [   
        {id:0,name: '其他'},   
        {id:1,name: '1分钟'},
        {id:2,name: '5分钟'},
        {id:3,name: '10分钟'},
        {id:4,name: '15分钟'},
        {id:5,name: '20分钟'},
        {id:6,name: '0.5小时'},
        {id:7,name: '1小时'},
        {id:8,name: '1.5小时'},
        {id:99,name: '不分析'},
      ],

     rules3: {
        num1: [
          { required: true, message: '请输入', trigger: 'blur' },
           { trigger: 'blur', validator: text1}
        ],
        num2: [
          { required: true, message: '请输入', trigger: 'blur' },
          { trigger: 'blur', validator: zheng}
        ],
        num3: [
            { required: true, message: '请输入', trigger: 'blur' },
          { trigger: 'blur', validator: zheng2}
        ],
        num4: [
            { required: true, message: '请输入', trigger: 'blur' },
          { trigger: 'blur', validator: zheng1}
        ],
      }, 


      formData4: {
        status:'0',
      },



      methodOptions: [
        {id: 1,name: '矩形窗', value:'0'},
        {id: 2,name: '低通滤波', value:'0'},
        {id: 3,name: '陷波滤波', value:'0'}
      ],
      show1: false,
      show2: false,
      show3: false,


      //动静分离
       formData5:{
         status:'',
         num1:'',
         num2:'',
      },

      
      rules5: {
          num2: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
        }, 



      formData: {
        status:'',
      },
      typeOptions: [
        {id:1,name: '全部'},
        {id:2,name: '荷载类'},
        {id:3,name: '环境类'},
        {id:4,name: '效应类'},
        {id:5,name: '衍生指标类'}
      ],
      targetOptions: [
        {id:1,name: '全部'},
        {id:2,name: '车辆荷载'},
        {id:3,name: '船舶及冰凌撞击荷载'},
        {id:4,name: '风荷载'},
        {id:5,name: '地震动监测'},
        {id:6,name: '其他'}
      ],
      selectedArr: [],
 
      // id: this.$route.params.id || sessionStorage.getItem('handleId'),
      targetId: this.$route.params.targetId || sessionStorage.getItem('analysisId'), //传感器id

      formData6:{
         number: '0',
         num: 1,
         num1:'',
         num2:'',
         num3:'',
         num4:'',
      },
      rules6: {
          num: [
            { required: true, message: '请输入', trigger: 'blur' },   
            { trigger: 'blur', validator: minData}
          ],
          num1:[
               { required: true, message: '请输入', trigger: 'blur' },    //numberMax
               { trigger: 'blur', validator: text1},
              //  { validator: checkedSerialNumber, trigger: "blur" }
          ],
           num2:[
               { required: true, message: '请输入', trigger: 'blur' },    //numberMax
               { trigger: 'blur', validator: text1},
              //  { validator: checkedSerialNumber, trigger: "blur" }
          ],
           num3:[
               { required: true, message: '请输入', trigger: 'blur' },    //numberMax
               { trigger: 'blur', validator: zheng},
              //  { validator: checkedSerialNumber, trigger: "blur" }
          ],
           num4:[
               { required: true, message: '请输入', trigger: 'blur' },    //numberMax
               { trigger: 'blur', validator: text1},
              //  { validator: checkedSerialNumber, trigger: "blur" }
          ],


        }, 
        formData7:{
          num:''
        }


      
    }
  },
  created () {
    this.state = sessionStorage.getItem('isAdmin');
    // this.targetId = sessionStorage.getItem('analysisId')

  },
  mounted () {
    // this.targetId = this.$route.params.targetId;
    // console.log('id', this.targetId)
    this.details1();
    // this.formData4.num5 = "Tue Mar 23 2021 14:02:15 GMT+0800 "

  },
  methods: {
    changeInput(){
      this.formData2.num3 = this.formData1.num2;
    },
    time(val){
      console.log(val)
      let time = new Date(val)
      this.formData4.num6 = new Date(time.setTime(time.getTime() + (30 * 60 * 1000))).Format('yyyy-MM-dd hh:mm:ss')
      console.log(this.formData4.num6)

      var arr =  new Date(val.setTime(val.getTime())).Format('yyyy-MM-dd hh:mm:ss')      // 2021-3-23 18:50:12

      var arr1=(arr).split(" ");
      this.startTime = arr1[1];
      console.log('this.startTime', this.startTime); // 10:10:10
    },
    time1(){
        console.log(this.formData4.num6)
    },




    back(){
      this.$router.push('/analysis')

    },

    //获取详情       传感器管理---id查询传感器信息
    details1(){
      this.$axios.get(`${this.baseURL}base/baseTarget/${this.targetId}`).then(res => {
        console.log('详情1',res);
        var data = res.data.data;
        this.msgList[0].value = data.code;    //编号
        this.msgList[1].value = data.typeGroupName;   //监测类型
        this.msgList[2].value = data.typeName;      //监测指标
        this.msgList[3].value = data.staticDynamicSelected == "1"?"静态效应":data.staticDynamicSelected=="2"?"动态效应":"振动响应";       //数据类型
        this.msgList[4].value = data.site;      //位置描述
        this.msgList[5].value = data.model;      //传感器型号
        this.msgList[6].value = data.coefficient;      //系数
        this.msgList[7].value = data.unit;      //单位
        this.msgList[8].value = data.precisions;      //精度
        this.msgList[9].value = data.serialNumber;      //通讯地址
         this.msgList[10].value = data.frequency;      //通讯地址

        if(data.staticDynamicSelected == 1){    //动静态
          this.quiet = 1;
        }

        this.details2();


        
        
      })
    },

    //详情主题   //配置管理   数据管理设置，根据id查询实例
    details2(){
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/option/${this.targetId}`).then(res => {
        var data = res.data.data;
        console.log('详情2',data);


        // this.targetId = data.targetId; //传感器ID

        // this.formData1.number = data.timeDuration;     //
        this.formData1.num2 = data.timePeriod;        //时间间隔  timePeriod 时域分析时间间隔（小时） 时域统计
        this.formData1.num3 = data.timeDuration;   //timeDurationOther",this.formData1.num3

        //温度效应
        this.formData2.number = data.tempEffectDuration;     
        // this.formData2.num2 = data.tempEffectPeriod;
          this.formData2.num2 = data.tempEffectAnalyseDuration;
        this.formData2.num1 = data.tempEffectDurationOther;
        this.formData2.num4 =  data.tempEffectIsolation;
        this.formData2.num5 =  data.tempEffectInterval;


        this.formData6.number =  (data.trendType).toString();    //趋势
        this.formData6.num =  data.trendOrder;

        this.formData6.num1 = data.trendDuration;
        this.formData6.num2 = data.trendPeriod;
        this.formData6.num3 = data.trendNPoint;
        this.formData6.num4 = data.trendInterval;

      
        if(data.frequencyDuration == null){
          this.formData3.number =7 ;
        }else{
            this.formData3.number = data.frequencyDuration;   //时域分析
        }

        this.formData3.num2 = data.frequencyPeriod;
        this.formData3.num1 = data.frequencyDurationOther;
        this.formData3.num3 = data.frequencyWindow;
        this.formData3.num4 = data.frequencySmooth;

        this.formData4.status = data.modalSelected? data.modalSelected.toString():'0';
        //  this.formData4.status = data.modalSelected;
      
    
        this.formData5.num2  = data.staticTimePeriod;   //准静态时域分析

        if(this.quiet != 1){
          console.log('动态');
          this.formData1.num2 = data.baselinePeriod;
          this.formData5.num2 = data.baselinePeriod;
          this.formData3.num2= data.baselinePeriod;
          this.formData2.num3 = data.baselinePeriod;
        }
        if(this.quiet !=  1){
          this.formData7.num2 = data.baselinePeriod;
        }
        if(this.quiet ==  1){  //静态的时候，温度效应的延迟时间==时间间隔
          this.formData2.num3 = this.formData1.num2
        }

        console.log('staticDynamicSeparationSelected 是否动静分离 1是0否', data.staticDynamicSeparationSelected)
        if(data.staticDynamicSeparationSelected == 1){
          this.zhunjingtai = 1;
        }else{
          this.zhunjingtai =0;
        }

      console.log('this.detime ', this.detime )
       console.log(' this.formData4.num5 ',  this.formData4.num5 )
        
      })
    },
  saveData(){

    if(this.quiet ==1){   //静态
        console.log(11111);
        console.log('this.formData1', this.formData1.num2)

        const p1=new Promise((resolve,reject)=>{
        this.$refs['formData1'].validate((valid) => {
          console.log(valid)
                  if(valid) resolve()
              })
        })



        const p6=new Promise((resolve,reject)=>{
        this.$refs['formData6'].validate((valid) => {
          console.log(valid)
                  if(valid) resolve()
              })
        })
        Promise.all([p1,p6]).then(()=>{
          console.log('验证通过,提交表单6') 
          
          this.updated();
        })
    }else{
        console.log(2222);
      this.saveData1();   //动态应用

    }
    
  },

   updated(){
     if(this.formData6.number == 0){
            this.formData6.num = '';
            this.formData6.num1 = '';
            this.formData6.num2 = '';
            this.formData6.num3 = '';
            this.formData6.num4 = '';
          }


      console.log('his.formData1.num2', this.formData1.num2)
      var param = new URLSearchParams();
      param.append("targetId",this.targetId);    //传感器id
      param.append("timePeriod",this.formData1.num2);    //时间间隔  timePeriod
      param.append("timeDuration",this.formData1.num2);    //时段长度   timeDuration
      param.append("trendType",this.formData6.number);    //趋势分析   timeDuration
      param.append("trendOrder",this.formData6.num);    //趋势分析-多项式   timeDuration

      param.append("trendDuration",this.formData6.num1);    //分析时段-多项式   timeDuration
      param.append("trendPeriod",this.formData6.num2);    //趋势分析-时间间隔（   timeDuration
      param.append("trendNPoint",this.formData6.num3);    //趋势分析-间隔点数   timeDuration
      param.append("trendInterval",this.formData6.num4);    //趋势分析-存储间隔（  timeDuration

      param.append("timeDurationOther",this.formData1.num3);    //时段长度
           //formData1.num3



      for (let p of param) {  //查看
        console.log(p);
       
      }

      this.$axios.post(`${this.baseURL}setttings/settingsDataAnalysis/save`, param).then(res => {
        console.log('提交更新',res);
         if(res.data.status == '200'){
            console.log(200)
              this.$router.push('/analysis');
          }
      })




    },



    saveData1(){
       if(this.formData6.number == 0){   //趋势分析为不分析时 值都为0
            console.log('00000')
              this.formData6.num ='';
              this.formData6.num1 ='';
              this.formData6.num2 = '';
              this.formData6.num3 = '';
              this.formData6.num4 = '';
          }
          
      console.log(999, this.formData1.num2)
      if(this.formData3.number != 0){
        this.formData3.num1 = '';
      }
      // const p1=new Promise((resolve,reject)=>{
      // this.$refs['formData1'].validate((valid) => {
      //           if(valid) resolve()
      //       })
      // })
        const p2=new Promise((resolve,reject)=>{
        this.$refs['formData2'].validate((valid) => {
                if(valid) resolve()
            })
      })
      
      const p3=new Promise((resolve,reject)=>{
        this.$refs['formData3'].validate((valid) => {
                if(valid) resolve()
            })
      })
      const p5=new Promise((resolve,reject)=>{
        this.$refs['formData5'].validate((valid) => {
                if(valid) resolve()
            })
      })
      const p6=new Promise((resolve,reject)=>{
        this.$refs['formData6'].validate((valid) => {
                if(valid) resolve()
            })
      })
  
      //  if(  this.formData3.num1 == 'null'){
      //      this.formData3.num1 = ''
      //  }
      // const p4=new Promise((resolve,reject)=>{
      //   this.$refs['formData4'].validate((valid) => {
      //           if(valid) resolve()
      //       })
      // })
       this.updated1();

      Promise.all([p2,p3,p5,p6]).then(()=>{
          // console.log('验证通过,提交表单') 
          if(this.formData1.number != 0){
            this.formData1.num1 =''
          }
          if(this.formData2.number != 0){
            this.formData2.num1 =''
          }
          if(this.formData3.number != 0){
            this.formData3.num1 =''
          }

          //当选择不分析时
          if( this.formData1.number == 99){
              this.formData1.num1 =''
              this.formData1.num2 = '';
              this.formData1.num3 = '';
          }
          if( this.formData2.number == 99){
             this.formData2.num1 =''
              this.formData2.num2 = '';
              this.formData2.num3 = '';
              this.formData2.num4 = '';
              this.formData2.num5 = '';
          }
          if( this.formData3.number == 99){
              this.formData3.num1 =''
              this.formData3.num2 = '';
              this.formData3.num3 = '';
              this.formData3.num4 = '';
          }

          if(this.formData6.number == 0){
            this.formData6.num = '';
            this.formData6.num1 = '';
            this.formData6.num2 = '';
            this.formData6.num3 = '';
            this.formData6.num4 = '';
          }

         

          this.updated1();
        })

    },

    updated1(){
      console.log(222222222222)
      var param = new URLSearchParams();
      param.append("targetId",this.targetId);    //传感器id
      // param.append("timeDuration",this.formData1.num2);    //时域
      // param.append("timePeriod",this.formData1.num2);           //时域
      // param.append("timeDurationOther",this.formData1.num1);    //时域

      // param.append("staticTimePeriod",this.formData5.num2);    //准静态

      
       param.append("frequencyDuration",this.formData3.number);    //频域分析   
      // param.append("frequencyPeriod",this.formData3.num2);          //频域时间间隔
      param.append("frequencyDurationOther",this.formData3.num1);    //频域
      param.append("frequencyWindow",this.formData3.num3);    //频域
      param.append("frequencySmooth",this.formData3.num4);    //频域


      param.append("tempEffectDuration",this.formData2.number);    //温度
      param.append("tempEffectAnalyseDuration",this.formData2.num2);    //温度
      param.append("tempEffectIsolation",this.formData2.num4);         //温度效应提取时间间隔
      param.append("tempEffectInterval",this.formData2.num5);    //温度效应提取存储间隔

      param.append("modalSelected",this.formData4.status);    //模态分析

      param.append("trendType",this.formData6.number);    //趋势分析   timeDuration
      param.append("trendOrder",this.formData6.num);    //趋势分析-多项式   timeDuration

      param.append("trendDuration",this.formData6.num1);    //分析时段-多项式   timeDuration
      param.append("trendPeriod",this.formData6.num2);    //趋势分析-时间间隔（   timeDuration
      param.append("trendNPoint",this.formData6.num3);    //趋势分析-间隔点数   timeDuration
      param.append("trendInterval",this.formData6.num4);    //趋势分析-存储间隔（  timeDuration




      // console.log('11', this.formData4.num3)


      for (let p of param) {  //查看
        console.log('查看', p);
       
      }

      this.$axios.post(`${this.baseURL}setttings/settingsDataAnalysis/save`, param).then(res => {
        console.log('提交更新',res);
         if(res.data.status == '200'){
            // console.log(200)
              this.$router.push('/analysis');
          }
      })




    },


    //是否1
    changeNumber1(){
      console.log(this.formData1.status)
    },
    changeNumber2(){
       console.log(this.formData2.number)
    },
     changeNumber3(){
       console.log(this.formData3.number)
    },
      changeNumber4(){
       console.log(this.formData4.status)
    },
    changeNumber44(){
      console.log(this.formData4.num4)
    },
    changeNumber6(){
       console.log(this.formData6.number)
    },
    getNum(){
      this.formData6.num = this.formData6.num - 1;
      if(this.formData6.num <= 1){
        this.formData6.num = 1
      }
      console.log('this.formData6.num', this.formData6.num)
    },
    getNum6(){
     this.formData6.num = this.formData6.num +1;
      if(this.formData6.num >= 4){
        this.formData6.num = 4
      }
      console.log('this.formData6.num', this.formData6.num)
    },


    //消噪处理方式
    changeArr(){
      console.log( this.formData3.selectedArr)
      this.checkboxSelect();
    },

    //消噪方法是否显示
    checkboxSelect(){

      this.formData3.selectedArr.forEach((item, i)=>{
                if(item == 1){
                    this.show1 = true;
                    return true;
                }else{
                    this.show1 = false;
                }
        })
   

      // console.log(this.show1,this.show2,this.show3)


    },



   
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__error{
  top: 42px;
}

@media screen and (min-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 106px;   //178
    }
    .el-form-item__label {
      width: 117px;
    }
    .multi-form .el-form {
      // padding: 0 1% 0 6.47%;
    }
    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }
      .el-form-item:nth-child(2) {
        margin-right: 100px;
      }
      .el-form-item__label {
        width: 100px;
      }
    }
    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 70px;
        }
      }
    }
    .limit-value {
      .el-form-item {
        width: 258px;
        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}
@media screen and (max-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }
    .el-form:first-child,
    .derive {
      .el-form-item__label {
        width: 110px;
      }
    }
    .multi-form .el-form {
      padding-left: 20px;
    }
    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }
      .el-form-item:nth-child(2) {
        margin: 0 80px 0 15px;
      }
      .el-form-item__label {
        width: 60px;
      }
    }
    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 72px;
        }
      }
      .el-form-item {
        margin-right: 20px !important;
      }
    }
    .limit-value {
      .el-form-item {
        width: 220px;
        .el-form-item__label {
          margin-right: 10px !important;
        }
        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }
      .el-form-item:nth-child(odd) {
        width: 200px;
        .el-form-item__label {
          width: 70px !important;
        }
      }
    }
  }
  .derive::v-deep {
    .el-input,
    .el-input__inner {
      width: 183px;
    }
  }
}
  .el-form::v-deep {
    .el-form-item__label {
      padding: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #0c265a;
    }
    .el-form-item__content {
      height: 60px;
      width: auto;
      line-height: 60px;
      text-align: center;
    }
    .el-input__inner {
      padding: 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .el-select {
      .el-input__inner {
        text-align: left;
        padding-left: 15px;
        border: 1px solid rgba(151, 151, 151, 0.29);
      }
      .el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      border: 1px solid #383E41 !important;
      background: rgba(56, 63, 65, 0.29) !important;
    }
  }
  .long-input::v-deep {
    .el-input__inner {
      width: 90%;
    }
  }
  p {
    color: #DBF804;
    line-height: 40px;
    text-align: center;
  }
  .el-form-item {
    margin: 0;
    border-top: 1px solid #5e5e5f2e;
  }
  .el-form-item {
    display: inline-flex;
    &::v-deep {
      .el-form-item__content {
        flex: 1;
      }
    }
  }
  .title {
    width: 148px;
    display: flex;
    word-wrap: none;
    white-space: nowrap;
    color: #DBF804;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #5e5e5f2e;
    background: #0c265a;
  }
  .derive {
    &::v-deep {
      .el-form-item__label {
        // margin-right: 22px;
      }
    }
  }
  .multi-form,
  .el-form:first-of-type,
  .el-form:nth-of-type(2) {
    margin: 4px;
    border-radius: 8px;
    margin-bottom: 6px;
    border: 1px solid #5e5e5f2e;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  }
  .el-form:first-child {
    &::v-deep .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .el-form-item {
      width: 25%;
    }
    .el-form-item:nth-of-type(5) {
      width: 100%;
    }
    .el-form-item:nth-last-of-type(2) {
      width: 50%;
    }
    .el-form-item:nth-of-type(n+6) {
      border-bottom: 1px solid #5e5e5f2e;
    }
    .el-form-item:nth-of-type(n+10) {
      border: none;
    }
  }
  .el-form:nth-child(2) {
    .el-form-item {
      display: block;
      &::v-deep {
        .el-form-item__content {
          display: inline-flex;
        }
      }
      .el-select:not(:last-child) {
        // margin-right: 22px;
      }
    }
  }
  .multi-form {
    section {
      display: flex;
      border-top: 1px solid #5e5e5f2e;
      p {
        width: 117px;
        height: 60px;
        line-height: 60px;
        background: #0c265a;
      }
      .el-form {
        flex: 1;
        margin: 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        .el-form-item {
          border: none;
          height: 60px;
          &::v-deep {
            .el-form-item__label {
              text-align: left;
              // margin-right: 20px;
              white-space: nowrap;
              background: transparent;
            }
          }
          .unit {
            padding: 0 12px;
            white-space: nowrap;
          }
        }
      }
    }
    .denosing-form {
      .el-form {
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid #5e5e5f2e;
      }
      .el-form-item:nth-of-type(2) {
        width: 50%;
        &::v-deep {
          .el-form-item__content {
            white-space: nowrap;
          }
        }
      }
    }
    .rehandle {
      .el-form-item:first-child {
        &::v-deep .el-form-item__label{
          width: 182px;
        }
      }
    }
  }
  .multi-form:nth-of-type(-n+1) {
    .el-form-item {
      margin-right: 4%;
    }
  }
  .set-time {
    padding: 0 2% !important;
  }
  .set-threshold {
    .title {
      border: none;
    }
    .form-title {
      margin: 20px 0;
      span {
        width: 50%;
        display: inline-block;
      }
    }
    .form-list {
      flex: 1;
      padding: 0 0 23px 2%;
      .el-form {
        padding: 0;
        &::v-deep {
          .el-form-item__content {
            width: 78px;
          }
          .el-form-item__label {
            padding: 0;
            width: 90px;
          }
        }
      }
      .limit-value {
        width: 50%;
        .el-form-item {
          display: inline-flex;
        }
      }
    }
  }
  .btns {
    margin-top: 10px;
    text-align: center;
    .el-button {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    .el-button:last-child {
      margin-left: 50px;
      background: #1E2529;
    }
  }

  .div{
    margin-bottom: 10px;
    ::v-deep .el-form{
      .ml20{
        margin-left: 20px;
      }
      .el-form-item__label{
        font-size: 16px;
        font-weight: 500;
        color:#fff;
        text-align: left;
        line-height: 30px;
        width: 102px;
        padding:0;
      }
      .el-form-item{
        margin-bottom: 0;
        display: flex;
        &.mB20{
          margin-bottom: 20px;
        }
        .el-form-item__content{
          margin-left: 0 !important;
          line-height: 30px;
        }
      }
      .line{
        padding: 0 10px;
      }
      .el-input{
        width: 134px;
        height: 30px;
        .el-input__inner{
          font-size: 16px;
          color:#fff;
        }
        .el-select__caret{
          line-height: 30px;
        }
        &.el-date-editor{
          width: 162px;
          .el-input__inner{
            padding:0 0 0 8px;
            font-size: 14px;
          }
          .el-input__prefix{
            display: none;
          }
        }

      }
      .el-button.ml24{margin-left: 24px;}
    }
    .title{margin:14px 0 8px 0;}
    .msgList{
      li{
        height: 48px;
        line-height: 48px;
        margin-top: 4px;
        .labelTxt{
          padding-left: 20px;
          background: rgba(48, 56, 58, 0.69);
          min-width: 131px;
          span{padding-left: 4px;}
        }
        .contentTxt{
          font-weight: 500;
          padding-left: 16px;
          width: 134px;
          background: rgba(34,39,40, 0.41);
        }
        &:nth-child(5){
          flex:1;
          .contentTxt{
            flex: 1;
            max-width: 399px;
          }
        }
      }
    }
    .myChart{
      height: 200px;
    }
    .el-checkbox{
      margin-left: 38px;
      margin-right: 0;
    }
    .toolTipList{
      li{
        margin-left: 14px;
        &:first-child{
          margin-left: 0;
        }
        .lineColor{
          width:39px;
          height: 1px;
          //background-color: #8AE8FF;
        }
        .pl10{
          padding-left: 8px;
        }
      }
    }
  }
  //@media screen and (max-width:1540px) {
  @media screen and (max-width:1848px) {
    .main{
      ::v-deep .el-form{
        .el-form-item__label {
          font-size:14px;
          width: 94px;
        }
        .ml20{
          margin-left: 0px;
        }
        .el-input{
          width: 120px;
          height: 30px;
          .el-input__inner{
            font-size: 14px;
          }

        }
        button.el-button{
          width: 90px;
          height: 30px;
          flex-shrink: 0;
          &.ml24{margin-left: 10px;}
        }
      }
    }

  }

//

  @media screen and (min-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 106px;
    }
    .el-form-item__label {
      width: 117px;
    }
    .multi-form .el-form {
      // padding: 0 1% 0 6.47%;
    }
    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }
      .el-form-item:nth-child(2) {
        margin-right: 100px;
      }
      .el-form-item__label {
        width: 100px;
      }
    }
    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 70px;
        }
      }
    }
    .limit-value {
      .el-form-item {
        width: 258px;
        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}
@media screen and (max-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }
    .el-form:first-child,
    .derive {
      .el-form-item__label {
        width: 110px;
      }
    }
    .multi-form .el-form {
      padding-left: 20px;
    }
    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }
      .el-form-item:nth-child(2) {
        margin: 0 80px 0 15px;
      }
      .el-form-item__label {
        width: 60px;
      }
    }
    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 72px;
        }
      }
      .el-form-item {
        margin-right: 20px !important;
      }
    }
    .limit-value {
      .el-form-item {
        width: 220px;
        .el-form-item__label {
          margin-right: 10px !important;
        }
        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }
      .el-form-item:nth-child(odd) {
        width: 200px;
        .el-form-item__label {
          width: 70px !important;
        }
      }
    }
  }
  .derive::v-deep {
    .el-input,
    .el-input__inner {
      width: 183px;
    }
  }
}
  .el-form::v-deep {
    .el-form-item__label {
      padding: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #0c265a;
    }
    .el-form-item__content {
      height: 60px;
      width: auto;
      line-height: 60px;
      text-align: center;
    }
    .el-input__inner {
      padding: 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .el-select {
      .el-input__inner {
        text-align: left;
        padding-left: 15px;
        border: 1px solid rgba(151, 151, 151, 0.29);
      }
      .el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      border: 1px solid #383E41 !important;
      background: rgba(56, 63, 65, 0.29) !important;
    }
  }
  .long-input::v-deep {
    .el-input__inner {
      width: 90%;
    }
  }
  p {
    color: #DBF804;
    line-height: 40px;
    text-align: center;
  }
  .el-form-item {
    margin: 0;
    border-top: 1px solid #5e5e5f2e;
  }
  .el-form-item {
    display: inline-flex;
    &::v-deep {
      .el-form-item__content {
        flex: 1;
      }
    }
  }
  .title {
    width: 148px;
    display: flex;
    word-wrap: none;
    white-space: nowrap;
    color: #DBF804;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #5e5e5f2e;
    background: #0c265a;
  }
  .derive {
    &::v-deep {
      .el-form-item__label {
        // margin-right: 22px;
      }
    }
  }
  .multi-form,
  .el-form:first-of-type,
  .el-form:nth-of-type(2) {
    margin: 4px;
    border-radius: 8px;
    margin-bottom: 6px;
    border: 1px solid #5e5e5f2e;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  }
  .el-form:first-child {
    &::v-deep .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .el-form-item {
      width: 25%;
    }
    .el-form-item:nth-of-type(5) {
      width: 100%;
    }
    .el-form-item:nth-last-of-type(2) {
      width: 50%;
    }
    .el-form-item:nth-of-type(n+6) {
      border-bottom: 1px solid #5e5e5f2e;
    }
    .el-form-item:nth-of-type(n+10) {
      border: none;
    }
  }
  .el-form:nth-child(2) {
    .el-form-item {
      display: block;
      &::v-deep {
        .el-form-item__content {
          display: inline-flex;
        }
      }
      .el-select:not(:last-child) {
        // margin-right: 22px;
      }
    }
  }
  .multi-form {
    section {
      display: flex;
      border-top: 1px solid #5e5e5f2e;
      p {
        width: 117px;
        height: 60px;
        line-height: 60px;
        background: #0c265a;
      }
      .el-form {
        flex: 1;
        margin: 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        .el-form-item {
          border: none;
          height: 60px;
          &::v-deep {
            .el-form-item__label {
              text-align: left;
              // margin-right: 20px;
              white-space: nowrap;
              background: transparent;
            }
          }
          .unit {
            padding: 0 12px;
            white-space: nowrap;
          }
        }
      }
    }
    .denosing-form {
      .el-form {
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid #5e5e5f2e;
      }
      .el-form-item:nth-of-type(2) {
        width: 50%;
        &::v-deep {
          .el-form-item__content {
            white-space: nowrap;
          }
        }
      }
    }
    .rehandle {
      .el-form-item:first-child {
        &::v-deep .el-form-item__label{
          width: 182px;
        }
      }
    }
  }
  .multi-form:nth-of-type(-n+1) {
    .el-form-item {
      margin-right: 4%;
    }
  }
  .set-time {
    padding: 0 2% !important;
  }
  .set-threshold {
    .title {
      border: none;
    }
    .form-title {
      margin: 20px 0;
      span {
        width: 50%;
        display: inline-block;
      }
    }
    .form-list {
      flex: 1;
      padding: 0 0 23px 2%;
      .el-form {
        padding: 0;
        &::v-deep {
          .el-form-item__content {
            width: 78px;
          }
          .el-form-item__label {
            padding: 0;
            width: 90px;
          }
        }
      }
      .limit-value {
        width: 50%;
        .el-form-item {
          display: inline-flex;
        }
      }
    }
  }
  .btns {
    margin-top: 10px;
    text-align: center;
    .el-button {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    .el-button:last-child {
      margin-left: 50px;
      background: #1E2529;
    }
  }


.marginL10{
  margin-left: 10px !important;
}
.marginR10{
  margin-right: 10px !important;
}

::v-deep .itemForm label{
  // padding-left: 40px !important;
}

//浏览版 按钮样式
.hui{
     cursor: not-allowed;
      background: #1E2529 !important;
}
.hui:hover{
      cursor: not-allowed;
      background: #1E2529 !important;
}

::v-deep .el-form-item__label{
  text-align: center !important;
}

::v-deep div .el-message{
  min-width: 350px !important;
}

::v-deep .el-date-editor .el-date-editor {
  width: 115px  !important;
}

</style>